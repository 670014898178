import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import PlainContent from "@/components/plain-content";
import { privacyData } from "@/data";

const PrivacyPolicyPage = () => {
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <Layout PageTitle="Privacy Policy Page">
                    <HeaderOne />
                    <PageBanner title="Privacy Policy" name="Privacy Policy" />
                    <PlainContent title="" content={privacyData} />
                    <Footer />
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default PrivacyPolicyPage;